import React from "react"

import { useIntl } from "react-intl"

import { SendResetPasswordForm } from "~/components/Forms"
import SEO from "~/components/Seo/Seo"
import UserFormTemplate from "~/components/UserFormTemplate/UserFormTemplate"
import useIsClient from "~/hooks/useIsClient"
import withTemplate from "~/templates/withTemplate"

function SendResetPassword() {
  const intl = useIntl()

  const isClient = useIsClient()

  return isClient ? (
    <>
      <SEO title={intl.formatMessage({ id: "profile/text:profile" })} />

      <UserFormTemplate
        title={intl.formatMessage({ id: "profile/text:what-is-your-email" })}
        Form={<SendResetPasswordForm />}
      />
    </>
  ) : null
}

export default withTemplate(SendResetPassword)
